<template>
  <body>
    <div class="page__bd">

      <!--      <div class="hm_view_nav">
        <a href="javascript:;" class="sec_newewm hbzd">海报<em class="iconfont icon-jinrujiantou f12"></em></a>
      </div> -->
      <!--      <header class="x_header bgcolor_11 cl f15">
        <a class="z f14" href="javascript:window.history.go(-1);">
          <i class="iconfont icon-fanhuijiantou w15"></i>返回
        </a>
        <a class="y sidectrl view_ctrl"><i class="iconfont icon-fenlei f14"></i></a>
        <div class="navtitle">快乐建家房产网站手机版</div>
      </header> -->
      <!--      <div class="x_header_fix"></div>
      <div class="none view_tools_mask"></div>
      <div class="none view_tools animated">
        <ul>
          <li class="border_bottom"><a href="http://happyhouse.ailaiwang.net/mobile/"><i
                class="vm iconfont icon-fenlei "></i>首页</a></li>
          <li>
            <a href="http://happyhouse.ailaiwang.net/mobile/my.php"><i class="vm iconfont icon-xiaolian2 "></i>我的</a>
          </li>
        </ul>
      </div> -->

      <div class="swipe cl" data-speed="5000" style="visibility: visible; max-height: 280px; height: auto;">
        <div class="swipe-wrap" style="width: 100%;">
          <div class="imgloading" data-index="0"
            style="left: 0px; transition-duration: 0ms; transform: translate(0px, 0px) translateZ(0px); display: table-cell; vertical-align: top;">
            <a><img :src="img_url"></a>
          </div>
        </div>
        <span class="bianhao" id="house_id">信息编号：{{id}}</span>
        <nav class="cl bullets bullets1">
          <ul class="position">
          </ul>
        </nav>
      </div>

      <div class="m-information-qm" style="background-color:#f8f8f8;border-bottom:none;">
        <div class="wp">
          <div class="top istrade0">
            <div class="tit" id="house_title">{{housename}}</div>
            <i class="deal"></i>
          </div>


          <ul class="ul-txt1-qm">
            <li class="display1590"><span>{{typeid=='1' ? '月租' : '售价'}}<b><em id="price">{{price}}
                    {{typeid=='1' ? '元' : '万元'}}</em></b></span>
            <li><span>户型<b><em>{{room}}室{{hall}}厅{{toilet}}卫</em></b></span></li>
            <li><span>面积<b><em id="houseearm">{{houseearm}}㎡</em></b></span></li>
          </ul>
          <div class="m-label1-qm">
            <span class="s1" style="background-color:#ff6565;color:#fff;">{{typetitle}}</span><span class="s1">
              <a>{{cattitle}}</a>
            </span>
          </div>
          <div class="m-txt1-qm txt2">
            <!--        <p><span>单价：</span><em id="price_dan" v-if="typeid=='0'">{{price}}元/㎡</em></p> -->
            <p><span>{{typeid=='1' ? '月租：' : '售价：'}}</span><em id="price" v-if="typeid=='0'">{{price}}
                {{typeid=='1' ? '元' : '万元'}}</em></p>
            <p>
              <span>楼层：</span>{{floor1}}楼/共{{floor2}}层
            </p>
            <p><span>朝向：</span>{{toward}}</p>
            <p><span>装修：</span>{{fitup}}</p>
            <p><span>房龄：</span>{{houseyear}}年</p>
            <p><span>浏览：</span>{{hits}}次</p>
            <p><span>更新：</span><em id="adddate">{{add_date}}</em></p>
          </div>

          <a class="g-addr-qm">
            小区：<span>
              {{housename}}<em></em>
            </span><i></i>
          </a>
          <a class="g-addr-qm">
            地区：<span>
              {{housearea}}<em></em>
            </span><i></i>
          </a>
        </div>

        <div class="m-report-qm" style="margin-top:10px;">
          <a @click="report();" class="clearfix"><i></i>举报</a>
          <div class="txt">
            <h3>如遇无效、虚假、诈骗信息，点击举报</h3>
            <p>涉及交易请仔细验证对方资质，签订规范合同</p>
          </div>
        </div>

        <div class="m-describe-qm">
          <div class="g-tit1-hlg bgcf">
            <a class="clearfix">
              <div class="left">
                <h3 class="line">信息描述</h3>
              </div>
            </a>
          </div>
          <div class="wp">
            <div class="txt">
              {{content}}
            </div>
          </div>
        </div>


      </div>


      <div class="foot_link" id="foot_link">
        友情提醒：该信息由网友自行发布，不代表本网认可并赞同其观点及真实性，请自主甄别。如遇虚假信息可拨打客服电话或在线举报，涉及交易时请仔细验证对方资质，签订规范的交易合同。
      </div>
      <div class="swiper-container global-lightbox animated" id="globalLightbox">
        <div class="swiper-wrapper" id="globalWrapper">
        </div>
        <div class="swiper-pagination lightbox-pagination"></div>
        <a class="iconfont icon-guanbijiantou closeLightbox"> </a>
      </div>


      <!--      <div id="backtotop" class="backtotop backtotop_show">
        <span class="icon-vertical-align-top">
          <i class="iconfont icon-iconfontarrowup"></i>
        </span>
      </div> -->
      <!--      <stab :config="config"></stab> -->


      <div class="footer_fix"></div>
      <div class="view_bottom weui-flex border_top" style=" display: flex;">

        <div class="view_bottom_z" @click="home()">
          <span style="position: relative;">
            <img class="userData-fixed-xiaoxi" src="../../assets/img/home0.png" style="width:24px;text-align: center; ">
            <p class="weui-tabbar__label">首页</p>
          </span>

        </div>

        <div class="view_bottom_z" @click="like()">
          <span style="position: relative;">
            <!-- <img class="userData-fixed-xiaoxi" src="../../assets/img/ico01.png" style="width:24px;text-align: center; "> -->
            <img v-if="Ilike == '0'" :src="require('../../assets/img/ico01.png')"
              style="width:24px;text-align: center; " />
            <img v-else :src="require('../../assets/img/ico11.png')" style="width:24px;text-align: center; " />
            <p class="weui-tabbar__label">收藏</p>
          </span>

        </div>
        <div class="view_bottom_z" @click="deal_done()">
          <span style="position:relative;">
            <img class="userData-fixed-xiaoxi" src="../../assets/img/ico04.png" style="width:24px;text-align: center; ">
            <p class="weui-tabbar__label">成交</p>
          </span>

        </div>
        <div class="view_bottom_z" @click="report()">
          <span style="position: relative;">
            <img class="userData-fixed-xiaoxi" src="../../assets/img/ico02.png" style="width:24px;text-align: center; ">
            <p class="weui-tabbar__label">客服</p>
          </span>

        </div>
        <div class="weui-flex__item view_bottom_y" @click="message()">
          <a>
            <i class="iconfont"></i>
            立刻咨询
          </a>
        </div>
      </div>


    </div>
    <!-- 主页面结束-->

  </body>

</template>

<script>
  import '../../../public/css/info-mb.css';
  import '../../../public/css/nav.css';
  import '../../../public/css/custom.css';
  import wx from 'weixin-js-sdk';
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  import {
    Toast
  } from "vant";
  export default {
    data() {
      return {
        id: '', //房屋id
        Ilike: '0', //默认不关注
        issuer_code: '', //所有者编码
        img_url: '',
        housename: '', //小区
        title: '',
        add_date: '',
        price: '',
        housearea: '', //省市区县
        houseearm: '', //面积
        room: '',
        hits: '0',
        hall: '',
        toilet: '',
        balcony: '',
        houseyear: "2020", //房屋年龄
        floor1: '', //几楼
        floor2: '', //共几层
        editdate: '', //修改日期
        address: "",
        content: "", //详情
        toward: "", //朝向
        catid: "", //物业类型
        typeid: "0", //1-出租还是0-出售
        cattitle: "",
        typetitle: "出售", //1-出租还是0-出售
        fitup: "豪华装修", //装修类型
        towardlist: ['', '东', '西', '南', '北', '东南', '西南', '南北', '东北', '西北', '东西']
      }

    },
    mounted() {
      //判断一下，cookie如果丢失了，那么就重新登录
      if (Cookies.get("kh") == "" || Cookies.get("kh") == null) {
        //如果获取用户信息失败那就直接跳到登录
        this.$router.push("login");
        return;
      }


      this.getHouseDetails();
      this.getWxConfig(); //加载js
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });

        } else {
          Toast(result.msg);
        }
      },

      async getHouseDetails() {
        //查询房屋信息
        let url = location.href;
        let id = decodeURI(getQueryVariable(url, "id"));
        var that = this;
        var uri = this.$global.domain + "/tools/postmysql.ashx";
        var param = {
          act: "getHouseDetails",
          id: id, //本次查询的房屋编号
          user: Cookies.get("kh"),
          sitenum: '18777777777',
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          let data = result.data;
          that.id = data[0][0];
          that.img_url = data[0][2];
          that.title = data[0][1];
          that.add_date = data[0][9];
          that.price = data[0][3];
          that.houseearm = data[0][8]; //面积 
          that.housearea = data[0][17]; //所在地区
          that.room = data[0][4];
          that.hall = data[0][5];
          that.toilet = data[0][6];
          that.balcony = data[0][7];
          that.content = data[0][10];
          that.housename = data[0][11]; //小区
          that.address = data[0][12]; //地址
          that.toward = that.towardlist[data[0][13]]; //朝向
          that.issuer_code = data[0][15]; //所有者编号
          that.floor1 = data[0][19]; //
          that.floor2 = data[0][20]; //
          that.catid = data[0][14]; //物业类型 cattitle
          switch (that.catid) { //想要判断的变量
            case '1':
              that.cattitle = "住宅";
              break
            case '2':
              that.cattitle = "公寓";
              break
            case '3':
              that.cattitle = "商铺";
              break
            case '4':
              that.cattitle = "写字楼";
              break
            case '5':
              that.cattitle = "厂房";
              break
            case '6':
              that.cattitle = "农民房";
              break
            case '7':
              that.cattitle = "其它";
              break
          }
          that.typeid = data[0][16]; //出租还是出售
          if (that.typeid == "0") {
            that.typetitle = "出售";
          } else {
            that.typetitle = "出租";
            //出租有些字眼,和出售不一样
          }
          that.hits = data[0][21]; //点击量
          that.fitup = data[0][24]; //装修
          switch (that.fitup) {
            case '1':
              that.fitup = "不限";
              break
            case '2':
              that.fitup = "毛坯";
              break
            case '3':
              that.fitup = "精装";
              break
            case '4':
              that.fitup = "简装";
              break
            case '5':
              that.fitup = "豪华装修";
              break
          }
          // kh: data[i].kh,
          if (result.isLike == "1") {
            that.Ilike = "1";
          }


        } else {

          this.$router.push("login");
        }
      },
      message(item) {
        //消息中心
        this.$router.push({
          path: '/chat',
          query: {
            'userCode': this.issuer_code
          }
        });
      },
      deal_done(item) {
        //成交
        this.$router.push({
          path: '/deal_done',
          query: {
            'id': this.id
          }
        });
      },
      async like() {
        //喜欢 关注
        let that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "collection2021",
          user: Cookies.get("kh"),
          code: this.id, //
          type: "3", //2是收藏会员，1是收藏商品  3是房产  4是招聘
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          //需要切换收藏图片状态
          if (that.Ilike == 0) {
            that.Ilike = "1";
          } else {
            that.Ilike = "0";
          }
        } else {}
      },
      home(item) {
        //房产租赁的首页
        this.$router.push({
          path: '/house_list',

        });
      },
      report() {
        //改为调用企业微信客服
        window.location.href = "https://work.weixin.qq.com/kfid/kfc87b5402ee83a9aec";
        //this.$router.push("chat?userCode=service");
      },
    },

    beforeMount() {
      // this.config[2].page = "chat"; //跳转这个人的聊天页面
      // this.config[2].userCode = "coco";
      // this.config[3].page = "deal_done"; //跳转这个人的聊天页面
      // this.config[3].house = "1";
    },
    components: {

    }
  }
</script>

<style>
  .weui-dialog__btn,
  .weui-navbar__item.weui_bar__item_on,
  .color-strong,
  .chip-row .toutiao,
  .weui-cells_checkbox .weui-check:checked+.weui-icon-checked:before,
  .weui-vcode-btn,
  .weui-dialog__btn_primary,
  .weui-cells_radio .weui-check:checked+.weui-icon-checked:before,
  .weui-icon-checked:before,
  .weui-agree__checkbox:checked:before,
  .weui-icon-success-circle,
  .weui-icon-success-no-circle,
  .weui-search-bar__cancel-btn,
  .weui-tabbar__item.weui-bar__item_on .weui-tabbar__icon,
  .weui-tabbar__item.weui-bar__item_on .weui-tabbar__icon>i,
  .weui-tabbar__item.weui-bar__item_on .weui-tabbar__label,
  .main_color,
  .weui-tabbar__item.weui-bar__item--on .weui-tabbar__label,
  .picker-button,
  .weui-form-preview__btn_primary {
    color: #00c07b !important
  }

  .weui_bar__item_on span:after,
  .weui-btn_primary,
  .weui-btn_primary:not(.weui-btn_disabled):active,
  .weui-btn_mini,
  .x_header,
  .main_bg,
  .position li.current,
  .position1 li.current,
  .post-tags .tag-on.weui-btn_default,
  .is-green,
  .weui-slider__track,
  .tsbtn_m {
    background-color: #00c07b !important
  }

  .foot_link {
    color: #aaa;
    font-size: 12px;
    margin: 0 0;
    padding: 10px 10px;
  }

  .foot_link .link {
    color: #ccc;
    font-size: 14px;
  }

  .foot_link a {
    color: #666;
  }

  .bianhao {
    display: block;
    padding: 0 10px;
    height: 25px;
    line-height: 25px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    position: absolute;
    top: 25px;
    left: 0;
    z-index: 9;
    background-color: rgba(0, 0, 0, .6)
  }

  .swipe-wrap img {
    height: 280px;
  }

  body,
  td,
  th {
    font-family: "Helvetica Neue", Roboto, "microsoft yahei";
  }
</style>
